<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  .font-blue {
    cursor: pointer;
  }

  .w-sm {
    min-width: 70px;
  }

  /deep/ .el-button--mini {
    padding: 7px 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getExamRecord,
    getExamDetails,
    deleteExamRecord,
    refresh,
    editExamRecord,
    clearData
  } from "@/api/admin/exam/examRecord.js"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"
  import {
    formatWithSeconds
  } from "@/libs/common.js"
  /**
   * 报名列表
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "考试报名信息列表 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "报名列表",
            active: true
          }
        ],
        tableList: [],
        classList: [],
        secondList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        },
        years: [],
        select: {
          lbbm: "",
          flbm: "",
          ksnf: new Date().getFullYear(),
          ksmc: ""

        },
        // 取当前时间并格式化
        time: formatWithSeconds(new Date),
        enableClearButton: true
      };
    },
    methods: {
      // 获取数据列表
      getList() {
        getExamRecord(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          /*  if(this.tableList) {
              this.tableList.forEach(k => {
                k.ksbmzt = k.ksbmzt == 1
              })
            }*/

          }
        })
      },
      getDetails(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          let detail = res.data
          let newsId = detail.bmtzh
          if (res.status) {
            if (newsId) {
              window.open("/admin/announcementDetails?newsId=" + newsId);

            }
          }
        })
      },

      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      // 条件查询
      getlistByParam() {
        getExamRecord({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      changeflmc() {
        let val = this.select.lbbm
        this.getSecondList({
          lbbm: val
        })
      },
      // 刷新
      getRefrsh(e) {
        if (e) {
          refresh(e).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '缓存刷新成功!'
              });
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请选择考试年份!'
          });
        }

      },
      // 删除
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.ksmc}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteExamRecord(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 改变启用状态
      changeQyzt(obj, val,type) {
        let f
        if(type=='ksbmzt'){
           f = {
            sid: obj,
            ksbmzt: val ? "1" : "0"
          }
        }else{
          f = {
            sid: obj,
            qywxbm: val ? '1' : '0'
          }
        }
        editExamRecord(f).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
        })
      },
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 清库
      clearBank(ksbmbh) {
        clearData(ksbmbh).then(res => {
          if (res.status) {
            this.$message({
              type: 'info',
              message: res.message

            });
          }
        })
      }

    },
    mounted() {
      this.getList()
      this.getClassListPage()
      this.getSecondList()
      this.getYear()
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="考试类别" v-model="select.lbbm"
                  size="small" @change="changeflmc">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.value" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 15%;" clearable placeholder="考试二级类别" v-model="select.flbm"
                  size="small">
                  <el-option :value="item.flbm" v-for="item in secondList" :key="item.value" :label="item.flmc">
                    {{item.flmc}}
                  </el-option>
                </el-select>

                <el-select class=" mr-2 " style="width:10%;" clearable placeholder="考试年份" v-model="select.ksnf"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-10" v-model="select.ksmc"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <a href="/admin/examEnroll">
                  <b-button variant="outline-info" class="flexList mr-2 condition"><i
                      class="iconfont icon-plus-circle1 mr-2"></i>添加考试</b-button>
                </a>
                <el-button type="primary" icon="el-icon-refresh" size="mini" @click="getRefrsh(select.ksnf)">刷新缓存
                </el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%"> 序号</th>
                    <th style="width:3%">年份</th>
                    <th style="width:6%">考试报名编号</th>
                    <th style="width:16%">考试类别</th>
                    <th>考试名称 </th>
                    <th style="width:24%">报名时间 </th>
                    <th style="width:5%">操作人 </th>
                    <th style="width:5%">微信报名 </th>
                    <th style="width:5%">报名状态 </th>
                    <th style="width:6%">管理 </th>
                    <th style="width:5%">清库 </th>
                    <th style="width: 3%;">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.ksnf}} </td>
                    <td>{{obj.ksbmbh}}</td>
                    <td>
                      {{obj.lbmc}}
                    </td>
                    <td>
                      <div class="font-blue" @click="getDetails(obj.ksbmbh)">{{obj.ksmc}}</div>
                    </td>
                    <td>{{obj.bmkssj}} <span class="m-1" v-if="obj.bmkssj&&obj.bmjssj">至</span>{{obj.bmjssj}}</td>
                    <!-- <td class="text-success">{{obj.ksbmzt==1?"正常":"不可用"}}</td> -->
                     <td >{{obj.modifyUser}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qywxbm" switch class="mt-1 switch-check"
                                       :value="1"
                                       :unchecked-value="0"    @change="changeQyzt(obj.sid,obj.qywxbm,'qywxbm')">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.ksbmzt" switch class="mt-1 switch-check" :value="1"
                                       :unchecked-value="0"
                        @change="changeQyzt(obj.sid,obj.ksbmzt,'ksbmzt')">
                      </b-form-checkbox>
                    </td>

                    <td><a :href="`/admin/examEnroll?sid=${obj.sid}&ksbmbh=${obj.ksbmbh}`">
                        <el-button type="primary" size="mini" class="h30"><i class="iconfont icon-come-l  mr-1"></i>管理
                        </el-button>
                      </a></td>
                    <td>
                      <el-button type="danger" size="mini" class="h30" :disabled="!(obj.bmkssj>time) || obj.ksbmzt"
                        @click="clearBank(obj.ksbmbh)">清库
                      </el-button>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj)"></i></td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
